import React from 'react'

const Social = () => {
  return (
    <div className="w-full flex justify-start mt-4 lg:mt-0 lg:justify-end">
    <strong>Follow Us:</strong>  
      <a href="https://www.facebook.com/kentandsussexpoolcare/" target="_blank" className="cursor-pointer inline-block social-item ml-4 text-gray-100 text-base font-extrabold transition duration-300 ease-in-out">
            <svg
              className="inline-block transition duration-300 ease-in-out mb-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"           >
              <g id="24 / symbols / facebook">
                <path
                  id="icon"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V20H20V4H4ZM11.1331 12.0044H13V18H15V12.0044H16.9824V10.0044H15V9C15 8.44772 15.4477 8 16 8H17V6H16C14.3431 6 13 7.34315 13 9V10.0044H11.1331V12.0044Z"
                />
              </g>
            </svg>
          </a>
          <a href="https://www.instagram.com/kentandsussexpoolcare/" target="_blank" className="cursor-pointer inline-block social-item ml-4 text-gray-100 text-base font-extrabold transition duration-300 ease-in-out">
            <svg
              className="inline-block transition duration-300 ease-in-out"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H6C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0ZM2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6ZM10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13ZM16 5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5C14 4.44772 14.4477 4 15 4C15.5523 4 16 4.44772 16 5Z"
              />
            </svg>
          </a>
    </div>
  )
}

export default Social