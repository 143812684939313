import React, { useState } from 'react'
import { Link } from 'gatsby'
import logo from '../img/kaspc-logo.jpg'



const NavbarTwo = () => {
  const [showMenu, toggleMenu] = useState(false);
  let menuClass = !showMenu ? 'hideNav' : '';
  const activeStyles = {
    color:'hsl(327,100%,45%)'
  }
  return (
    <div className="w-full px-4 bg-white pb-2 absolute lg:w-11/12 xl:w-10/12 lg:px-0 md:pb-0 md:static mx-auto flex justify-between items-center md:items-start flex-wrap lg:flex-no-wrap h-auto lg:h-auto" id="header-wrapper">
      <div className="navbar-brand z-30 w-6/12 lg:w-auto">
        <Link to="/" className="navbar-item">
          <figure className="image md:absolute mt-4">
            <img src={logo} alt="Kent and Sussex Pool Care"/>
          </figure>
        </Link>
      </div>
      {/* burger */}
      <div className="block lg:hidden">
        <div id="nav-toggle" className="flex items-center justify-end px-3 py-2 md:py-8 lg:py-2"  onClick={() => toggleMenu(!showMenu)}>
          <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path
              d="M0 24H36V20H0V24ZM0 14H36V10H0V14ZM0 0V4H36V0H0Z"
              fill="#E5007E"
            />
          </svg>
        </div>
      </div>
      <nav className="w-full lg:w-auto lg:flex lg:items-center lg:justify-between lg:flex-wrap">
        {/* menu */}

        {/* <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content"> */}
        <div
          className={`flex flex-col items-center justify-center py-2 lg:flex-row lg:justify-end lg:items-end ${menuClass}`}
          id="nav-content"
        >
          <Link
            className="py-2 lg:py-0 inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink"
            to="/"
            activeStyle={activeStyles}
          >
            Home
          </Link>
          <Link
            className="py-2 lg:py-0 inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink"
            to="/construction"
            activeStyle={activeStyles}
          >
            Construction
          </Link>
          <Link
            className="py-2 lg:py-0 inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink"
            to="/care"
            activeStyle={activeStyles}
          >
            Pool Care
          </Link>
          <Link
            className="py-2 lg:py-0 inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink"
            to="/prices"
            activeStyle={activeStyles}
          >
            Prices
          </Link>
          <Link
            className="py-2 lg:py-0 inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink"
            to="/contact"
            activeStyle={activeStyles}
          >
            Contact
          </Link>
          <a href="tel:01424575144" className="py-2 lg:py-0 cursor-pointer inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out hover:text-darkpink">
            <svg
              className="inline-block transition duration-300 ease-in-out mr-2"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.8614 6.33888C9.3795 5.64067 9.56529 4.90482 8.97932 4.25529C7.66437 2.41531 6.78003 1.27747 6.22533 0.729514C5.17077 -0.312219 3.436 -0.171309 2.52267 0.728624C2.03212 1.21199 1.86604 1.37797 1.36235 1.88959C-1.4433 4.69674 0.267729 10.631 4.81627 15.184C9.36378 19.736 15.2975 21.4479 18.109 18.6348C18.5738 18.1867 18.9673 17.793 19.2777 17.465C20.1727 16.5189 20.3086 14.8607 19.2718 13.7834C18.7402 13.2311 17.6552 12.3894 15.7378 11.0179C15.1506 10.4928 14.4543 10.6066 13.8159 11.0254C13.5089 11.2269 13.2854 11.4307 12.8634 11.853L12.0972 12.6197C11.9963 12.7207 10.6259 12.0344 9.2956 10.7028C7.9646 9.37051 7.27878 8.00004 7.37923 7.89959L8.146 7.1324C8.27988 6.99839 8.34388 6.93351 8.42596 6.84725C8.59697 6.66752 8.73856 6.50441 8.8614 6.33888ZM13.5106 14.0339L14.277 13.2671C14.5092 13.0346 14.6597 12.8917 14.7822 12.7932C16.4618 13.9994 17.4346 14.7578 17.8319 15.1707C18.0705 15.4185 18.0334 15.8708 17.8261 16.09C17.5391 16.3934 17.1662 16.7664 16.7086 17.2078C14.8906 19.0266 10.1008 17.6447 6.23001 13.7701C2.35803 9.89429 0.976766 5.10377 2.78108 3.29846C3.28274 2.78895 3.44168 2.63009 3.92524 2.15363C4.10654 1.97498 4.6004 1.93487 4.82096 2.15274C5.24802 2.57461 6.04022 3.58897 7.20559 5.21231C7.14526 5.28721 7.06991 5.37185 6.97821 5.46822C6.91091 5.53895 6.85484 5.59579 6.73226 5.71849L5.96624 6.48493C4.66309 7.78797 5.7729 10.0057 7.88186 12.1167C9.98936 14.2263 12.2079 15.3373 13.5106 14.0339Z"
              />
            </svg>
            01424 575144
          </a>
          <a href="https://www.facebook.com/kentandsussexpoolcare/" target="_blank" className="py-2 lg:py-0 cursor-pointer inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out">
            <svg
              className="inline-block transition duration-300 ease-in-out"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="24 / symbols / facebook">
                <path
                  id="icon"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V20H20V4H4ZM11.1331 12.0044H13V18H15V12.0044H16.9824V10.0044H15V9C15 8.44772 15.4477 8 16 8H17V6H16C14.3431 6 13 7.34315 13 9V10.0044H11.1331V12.0044Z"
                />
              </g>
            </svg>
          </a>
          <a href="https://www.instagram.com/kentandsussexpoolcare/" target="_blank" className="py-2 lg:py-0 cursor-pointer inline-block navbar-item lg:ml-6 xl:ml-8 text-gray-700 text-base font-extrabold transition duration-300 ease-in-out">
            <svg
              className="inline-block transition duration-300 ease-in-out"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H6C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0ZM2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6ZM10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13ZM16 5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5C14 4.44772 14.4477 4 15 4C15.5523 4 16 4.44772 16 5Z"
              />
            </svg>
          </a>
        </div>
      </nav>
    </div>
  )
}

export default NavbarTwo
