import React from 'react'
import Social from './blocks/Social'
import { Link, StaticQuery, graphql } from 'gatsby'

const Footer = () => {
    return(
        <footer className="bg-darkblue text-gray-100 lg:h-56 pb-4 overflow-hidden relative">
            <img className="hidden lg:block waveImg absolute" src="/img/wavewhite.svg" />
            <div className="w-10/12 mx-auto flex flex-col-reverse lg:flex-row lg:justify-between h-full lg:items-end">
                <div className="lg:w-6/12">
                    <div className="w-full">
                        <Link to="/terms" className="text-white">Terms and conditions</Link>
                    </div>
                    <div className="w-full">    
                        &copy; KASPC 2020 | Company registration number: 11810306
                    </div>
                </div>
                <div className="mb-4 lg:my-0 md:w-4/12 lg:w-3/12">
                    <strong>Business hours</strong>
                    <div className="flex justify-start md:justify-between">
                        <div className="mr-4">Mon - Fri</div><div>09:00 - 17:00</div>
                    </div>
                    <div className="flex justify-start md:justify-between">
                        <div className="mr-4">Sat</div><div>09:00 - 12:00</div>
                    </div> 
                    <div className="flex justify-start md:justify-between">   
                        <div className="mr-4">Sun</div><div>Closed</div>
                    </div>
                </div>
                <div className="lg:w-3/12 lg:pl-6">
                    <Social />
                </div>
            </div>
            
        </footer>
    )
} 

export default Footer;