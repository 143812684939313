import React from 'react'
import NavbarTwo from './NavbarTwo'
import classes from './Header.module.css'

const Header = (props) => {

  const headerStyle = {
    backgroundImage: 'url(' + props.featuredImg + ')',
    backgroundPosition: 'center center',
  };
  return (
    <header className={`w-full md:h-short lg:h-short xl:h-tall bg-cover ${classes.headerImage} relative`} style={headerStyle}> 
      <div className="z-40 w-full bg-white relative">
        <NavbarTwo />
      </div>
      <div className="relative overflow-hidden w-full h-auto waveContainer">
        <img className="waveImg z-20 absolute md:relative" src="/img/wavewhite.svg" />
      </div>
      <div className="w-full h-full darkBlueGrad absolute z-10 top-0"></div>
      {/* <div className={classes.wave}></div> */}
      <div className="absolute bottom-0 w-full text-center z-10">
  <h1 className="mx-auto text-shadow pb-6 text-gray-100" dangerouslySetInnerHTML={{
            __html: props.pageName,
          }}></h1>
      </div>
    </header>
  )
}

export default Header