import React from 'react'
import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
//import './all.sass'

const TemplateWrapper = props => (
  <div>
    <Helmet> 
    <meta name="google-site-verification" content="z-MBFjYSoG-gEMAV_rJL3AIWj5iYzwMPCN2TCHDwFA8" />
    </Helmet>  
    <div  className="sticky-footer">
    <div  className="sticky-footer-inner">
      <Header featuredImg={props.featuredImg} pageName={props.pageName} />
      <main className="text-center overflow-x-hidden">{props.children}</main>
      </div>
      <Footer />
    </div>
  </div>
)

export default TemplateWrapper
